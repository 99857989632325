<!-- eslint-disable max-len -->
<template>
  <div class="h-screen home">
    <!--    <NavBar />-->
    <!--    <div class="home pb-6 absolute" style="background: #ffebdd">-->
    <div class="container mx-auto">
      <div
        class="container mx-auto absolute"
        style="background: #faf6f1"
      >
        <!--         Hero Section-->
        <!--    <NavBar />-->

        <div class="flex flex-col items-center">
          <img src="../assets/colors.jpg" alt="" class="min-w-full md:hidden"/>
          <img src="../assets/hero-landscape.jpeg" alt="" class="min-w-full hidden md:block"/>
          <div
            class="absolute top-24 md:top-32 text-white text-5xl md:text-7xl font-cormorant-garamond text-center"
          >
            There's nothing <br />
            a fresh manicure can't fix
          </div>

          <div class="flex flex-row space-x-4 absolute top-[500px] md:top-[730px]">
            <div
              class="border border-solid border-white border-1 md:border-2 rounded-lg py-1 px-3 text-white text-lg font-medium"
            >
              <router-link to="/booking"> Book Now </router-link>
            </div>
            <div
              class="border border-solid border-white border-1 md:border-2 rounded-lg py-1 px-3 text-white text-lg font-medium"
            >
              <a href="tel:(405) 265-2494"> Call Us </a>
            </div>
          </div>
        </div>

        <!-- <p
              class="mx-6 pt-10 text-dark-primary text-4xl font-cormorant-garamond text-red-400"
            >
              Morgan Oasis Nails
            </p> -->
        <!-- <p class="mx-6 pt-4">(405) 265-2494</p>
            <p class="mx-6">1719 S Morgan Rd, Oklahoma City, OK 73128</p> -->
        <!--        <img src="../assets/logo.png" alt="" class="" />-->

        <div class="mx-6 py-10 text-center font-san md:text-lg md:mx-16">
          <!--        <div class="mx-6 mt-8 font-san">-->
          Welcome to
          <span class="" style="color: #ff5757">Morgan Oasis Nails</span>
          – where nails are transformed into dazzling statements of style. Our
          expert technicians create artistry that speaks volumes, pampering your
          nails with precision and creativity. Elevate your elegance with us
          today!
        </div>

        <!-- <div class=" text-red-400 text-2xl">
          <span class="material-symbols-outlined text-red-400 text-4xl"> location_on </span>
          Location
        </div> -->

        <!--      <div class="flex flex-col items-start mt-10 p-6 bg-neutral-100 space-y-6">-->
        <div
          class="flex flex-col items-start px-6 py-10 text-black space-y-6"
          style="background: #faf6f1"
        >
          <span class="font-cormorant-garamond text-3xl text-red-500"
            >About Us</span
          >
          <div class="flex flex-row space-x-4">
            <span class="material-symbols-outlined"> schedule</span>
            <p>
              Monday-Saturday: 9:30 AM - 7:00 PM <br />
              Sunday: Close
            </p>
          </div>

          <div class="flex flex-row space-x-4">
            <span class="material-symbols-outlined"> call</span>
            <a href="tel:(405) 265-2494" class="underline">(405) 265-2494</a>
          </div>

          <div class="flex flex-row space-x-4">
            <span class="material-symbols-outlined"> location_on </span>
            <p>
              1719 S Morgan Rd, Oklahoma City,<br />
              OK 73128
            </p>
          </div>

          <div class="flex flex-col space-y-4 items-end ml-16">
            <a href="https://maps.app.goo.gl/1XRet8YMW78zfssJA?g_st=ic">
              <div class="flex flex-row space-x-4">
                <p class="underline">Navigate with Google Map</p>
                <div
                  class="flex flex-col bg-white w-8 h-8 items-center justify-center rounded-md"
                >
                  <img class="w-6" src="../assets/google_map.png" alt="" />
                </div>
              </div>
            </a>

            <a
              href="https://maps.apple.com/?address=1719%20S%20Morgan%20Rd,%20Oklahoma%20City,%20OK%20%2073128,%20United%20States&auid=11128051628244022856&ll=35.448151,-97.690181&lsp=9902&q=Oasis%20Nails"
            >
              <div class="flex flex-row space-x-4">
                <p class="underline">Navigate with Apple Map</p>
                <!--              <spacer></spacer>-->
                <img class="w-8" src="../assets/apple_map.png" alt="" />
              </div>
            </a>
          </div>
        </div>

        <div class="flex flex-col items-start py-10">
          <!--        <img class="" src="../assets/service.jpg" alt="" />-->
          <span class="px-6 mb-4 font-cormorant-garamond text-3xl text-red-500"
            >Our Services</span
          >
          <div class="flex flex-col mb-4 text-black px-6 space-y-2">
            <div>Nails Enhancements</div>
            <div>Manicures</div>
            <div>Pedicures</div>
            <div>Waxing</div>
            <div>Kid's services</div>
          </div>

          <div class="px-6 underline">
            <router-link to="/menu"> Learn more </router-link>
          </div>
        </div>

        <Footer />
      </div>
    </div>

    <NavBar />
  </div>

  <!--  <div class="px-6">-->
  <!--    <swiper :pagination="true" :modules="modules" class="mySwiper">-->
  <!--      <swiper-slide>Slide 1</swiper-slide>-->
  <!--      <swiper-slide>Slide 2</swiper-slide-->
  <!--      ><swiper-slide>Slide 3</swiper-slide>-->
  <!--      <swiper-slide>Slide 4</swiper-slide-->
  <!--      ><swiper-slide>Slide 5</swiper-slide>-->
  <!--      <swiper-slide>Slide 6</swiper-slide-->
  <!--      ><swiper-slide>Slide 7</swiper-slide>-->
  <!--      <swiper-slide>Slide 8</swiper-slide-->
  <!--      ><swiper-slide>Slide 9</swiper-slide>-->
  <!--    </swiper>-->
  <!--  </div>-->
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import NavBarWithImage from "@/components/NavBarWithImage.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css/pagination";
// Import Swiper styles
import "swiper/css";
import { Pagination } from "swiper/modules";

export default {
  components: {
    NavBarWithImage,
    NavBar,
    Footer,
    // HomeBanner,
    // RocketTicketsWidget,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
};
</script>
